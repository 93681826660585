// Generated by Framer (db8c062)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";

const enabledGestures = {"ng6LZqqxI": {"hover": true}};

const cycleOrder = ["ng6LZqqxI"];

const variantClassNames = {"ng6LZqqxI": "framer-v-109dxds"};

const humanReadableVariantMap = {};

const transitions = {"default": {"type": "spring", "ease": [0.44, 0, 0.56, 1], "duration": 0.3, "delay": 0, "stiffness": 500, "damping": 60, "mass": 1}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, width, height, layoutId, variant: outerVariant = "ng6LZqqxI", image: r6rGSWlDA = new URL("assets/2048/e55mE4fd7dslGb9y9W7Y5o4HJA8.jpg", import.meta.url).href, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "ng6LZqqxI", variant, transitions, variantClassNames, enabledGestures, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const variantProps = React.useMemo(() => ({"ng6LZqqxI-hover": {"Q388LpTWE": {"background": {"src": r6rGSWlDA, "pixelWidth": 5472, "pixelHeight": 3648, "intrinsicWidth": 5472, "intrinsicHeight": 3648, "fit": "fill"}}}}), [r6rGSWlDA]);

const addVariantProps = useAddVariantProps(baseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ukATH", classNames)} style={{"display": "contents", "pointerEvents": "auto"}}>
<motion.div {...restProps} layoutId="ng6LZqqxI" className={cx("framer-109dxds", className)} style={{"borderBottomLeftRadius": 24, "borderBottomRightRadius": 24, "borderTopRightRadius": 24, "borderTopLeftRadius": 24, ...style}}  background={null} data-framer-name="Variant 1" transition={transition} layoutDependency={layoutDependency} ref={ref} {...addVariantProps("ng6LZqqxI")}><Image layoutId="Q388LpTWE" className="framer-1hzk9th" style={{"WebkitFilter": "none", "filter": "none", "boxShadow": "none"}}  background={{"src": r6rGSWlDA, "pixelWidth": 5472, "pixelHeight": 3648, "intrinsicWidth": 5472, "intrinsicHeight": 3648, "fit": "fill"}} alt="" variants={{"ng6LZqqxI-hover": {"WebkitFilter": "blur(4px)", "filter": "blur(4px)", "boxShadow": "none"}}} transition={transition} layoutDependency={layoutDependency} {...addVariantProps("Q388LpTWE")}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ukATH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ukATH * { box-sizing: border-box; }", ".framer-ukATH .framer-109dxds { position: relative; overflow: hidden; width: 1152px; height: 576px; }", ".framer-ukATH .framer-1hzk9th { position: absolute; overflow: visible; height: 576px; right: 0px; bottom: var(--framer-aspect-ratio-supported, 0px); left: 0px; top: 0px; flex: none; aspect-ratio: 2 / 1; }", ".framer-ukATH.framer-v-109dxds .framer-109dxds { cursor: pointer; }", ".framer-ukATH.framer-v-109dxds.hover .framer-1hzk9th { width: 1280px; height: 640px; right: auto; bottom: auto; left: calc(50.00000000000002% - 1280px/2); top: calc(50.00000000000002% - 640px/2); flex: none; aspect-ratio: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 576
 * @framerIntrinsicWidth 1152
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}, "GLkB2i_3a": {"layout": ["fixed", "fixed"]}}}
 * @framerVariables {"r6rGSWlDA": "image"}
 */
const FramerTKCaTaxDK: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerTKCaTaxDK;

FramerTKCaTaxDK.displayName = "Project Image";

FramerTKCaTaxDK.defaultProps = {"width": 1152, "height": 576};

addPropertyControls(FramerTKCaTaxDK, {"r6rGSWlDA": {"type": ControlType.Image, "title": "Image", "__defaultAssetReference": "data:framer/asset-reference,e55mE4fd7dslGb9y9W7Y5o4HJA8.jpg?originalFilename=iPhone+13+Pro+Max.jpg"}} as any);

addFonts(FramerTKCaTaxDK, []);